.fieldset-style {
  border: 2px solid #000000;
  border-radius: 8px;
}

legend {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding-right: 5px;
  padding-left: 5px;
}
.ql-video {
  display: block;
  max-width: 100%;
  width: 100%;
  min-height: 350px;
  height: auto;
}
